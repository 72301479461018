import React, { useState, useEffect } from 'react'; 
import MainBanner from './MainBanner';
import AboutUs from './AboutUs';
import Categories from './Categories';
import Contacts from './Contacts';
import { Link, useLocation  } from 'react-router-dom';
import { motion } from "framer-motion"

const HomePage = () => {

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
        let url = 'https://resanta.org/wordpress/wp-json/wc/v3/products/categories';
        let totalPages = 1;
        let currentPage = 1;
        let allCategories = [];

        while (currentPage <= totalPages) {
            try {
                const response = await fetch(`${url}?per_page=100&page=${currentPage}&hide_empty=true`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Basic ' + btoa('ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d'),
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                allCategories = allCategories.concat(data);

                const totalPagesHeader = response.headers.get('X-Wp-Totalpages');
                if (totalPagesHeader) {
                    totalPages = parseInt(totalPagesHeader);
                }

                currentPage++;
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            } finally {
                setLoading(false);
            }
        }

        // Filter top-level categories
        const topLevelCategories = allCategories.filter(category => category.parent === 0);

        setCategories(topLevelCategories);
    };

    fetchCategories();
}, []);

  return (
    <>
      <motion.div 
        initial={{ x: -100 }}
        whileInView={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.7 }}
        viewport={{ once: true }}
        >
        <MainBanner />
      </motion.div>

      <motion.div 
        initial={{ x: 100 }}
        whileInView={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.7 }}
        viewport={{ once: true }}
        >
        <AboutUs />
      </motion.div>


      <motion.div 
        initial={{ y: 100 }}
        whileInView={{ y: 0 }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        viewport={{ once: true }}
        >
        <Categories categories={categories} status={loading} />
      </motion.div>

      <Contacts />
    </>
  );
};

export default HomePage;
