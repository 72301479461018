import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BtnTo from "../BtnTo";
import formImg from '../../assets/images/aboutUs/formImg.jpg';
import { motion } from "framer-motion"


const Contacts = () => {
  const initialValues = {
    username: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  };

  const validationSchema = Yup.object({
    username: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required')
  });

  const [phone, setPhone] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    if (!value.startsWith('+')) {
      setPhone('+' + value.replace(/^\+/, ''));
    } else {
      setPhone(value);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch('https://resanta.org/submit.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const responseData = await response.json();
      console.log(responseData);
      setModalIsOpen(true); // Open the modal on successful submission
    } catch (error) {
      console.error('Error submitting data: ', error);
      alert('Error submitting data.');
    }

    setSubmitting(false);
  };

  return (
    <>
    <motion.div 
        initial={{ y: 100 }}
        whileInView={{ y: 0 }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        viewport={{ once: true }}
        >
      <section className="contacts contactsForm" id="contact">
      <style>
          {`
            .react-modal-overlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.75);
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1000;
            }
            .react-modal-content {
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 6px rgba(255, 0, 0, 0.1);
                text-align: center;
                max-width: 500px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 22px;
            }
            .form-container {
              display: flex;
              flex-direction: column;
                  gap: 7px;
            }
            label {
              margin-bottom: 5px;
              color: #900;
            }
            input, textarea {
              padding: 7px;
              margin-bottom: 0px;
              border: 1px solid #900;
              border-radius: 5px;
            }
            button {
              background-color: #d00;
              color: white;
              border: none;
              padding: 10px 20px;
              border-radius: 5px;
              cursor: pointer;
              transition: background-color 0.3s;
            }
            button:hover {
              background-color: #b00;
            }
            form div {
              display: flex;
              flex-direction: column;
            }
            .error-message {
              color: red;
              font-size: 12px;
              margin-top: 0px;
            }
          `}
        </style>
        <div className="container flex row">
          <div className="contacts__redCard flex">
            <h2 className="titleSection">Get in touch</h2>
              <div>
                <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C22 17.6569 22 15.7712 22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157ZM18.5762 7.51986C18.8413 7.83807 18.7983 8.31099 18.4801 8.57617L16.2837 10.4066C15.3973 11.1452 14.6789 11.7439 14.0448 12.1517C13.3843 12.5765 12.7411 12.8449 12 12.8449C11.2589 12.8449 10.6157 12.5765 9.95518 12.1517C9.32112 11.7439 8.60271 11.1452 7.71636 10.4066L5.51986 8.57617C5.20165 8.31099 5.15866 7.83807 5.42383 7.51986C5.68901 7.20165 6.16193 7.15866 6.48014 7.42383L8.63903 9.22291C9.57199 10.0004 10.2197 10.5384 10.7666 10.8901C11.2959 11.2306 11.6549 11.3449 12 11.3449C12.3451 11.3449 12.7041 11.2306 13.2334 10.8901C13.7803 10.5384 14.428 10.0004 15.361 9.22291L17.5199 7.42383C17.8381 7.15866 18.311 7.20165 18.5762 7.51986Z" fill="#fff"/>
                </svg>
                  <a href="mailto:info@resanta.org"><span>info@resanta.org</span></a>
              </div>
          </div>
          <div className="contacts__formWrapper">
            <div className="contacts__imgForm">
              <img src={formImg} alt="Form" />
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form className="form-container">
                  <div>
                    <label htmlFor="username">Name</label>
                    <Field id="username" name="username" placeholder="John Doe" />
                    <ErrorMessage name="username" component="div" className="error-message" />
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <Field id="email" name="email" type="email" placeholder="example@example.com" />
                    <ErrorMessage name="email" component="div" className="error-message" />
                  </div>
                  <div>
                    <label htmlFor="phone">Phone</label>
                    <Field
                      id="phone"
                      name="phone"
                      type="tel"
                      placeholder="+___(___) ___-__-__"
                      value={phone}
                      onChange={(e) => {
                        handlePhoneChange(e);
                        setFieldValue('phone', e.target.value);
                      }}
                    />
                    <ErrorMessage name="phone" component="div" className="error-message" />
                  </div>
                  <div>
                    <label htmlFor="subject">Subject</label>
                    <Field id="subject" name="subject" placeholder="Subject" />
                    <ErrorMessage name="subject" component="div" className="error-message" />
                  </div>
                  <div>
                    <label htmlFor="message">Message</label>
                    <Field id="message" name="message" as="textarea" placeholder="Message" />
                    <ErrorMessage name="message" component="div" className="error-message" />
                  </div>
                  <button type="submit" disabled={isSubmitting}>
                    Send
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      </motion.div>

      {/* <motion.div 
        initial={{ y: 100 }}
        whileInView={{ y: 0 }}
        transition={{ ease: "easeOut", duration: 0.5 }}
        viewport={{ once: true }}
        >
      <section className="contacts" id="contact">
        
        <div className="container flex row">
          <div className="contacts__redCard flex">
            <h2 className="titleSection">Contacts</h2>
          </div>
          <div className="contacts__grayCard flex column">
            <div className="contacts__grayTop">
              <h3>Contact us</h3>
              <div>
                
                
                
                
              </div>
            </div>
          </div>
          <div className="mapContainer">
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1705.7875367570944!2d121.51372862992517!3d31.23249997434707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDEzJzU1LjkiTiAxMjHCsDMwJzU0LjgiRQ!5e0!3m2!1sru!2snl!4v1716969177032!5m2!1sru!2snl" width="486" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </section>
      </motion.div> */}
      {modalIsOpen && (
        <div className="react-modal-overlay">
          <div className="react-modal-content">
            <h2>Thank you for your submission!</h2>
            <p>We have received your message and will get back to you shortly.</p>
            <button onClick={() => setModalIsOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Contacts;
