import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import searchImg from '../assets/images/aroowSearch.svg';

const Footer = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleSearchVisibility = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigate(`/search?term=${searchQuery}`);
    }
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      <footer>
        <div className="container flex">
          <div className="footer__logoContainer">
             <Link to="/"><img src={logo} alt="Resanta" /></Link>
          </div>

          <div className='footer__navContainer flex'>
            <nav className='flex align-center'>
              <Link to="/#about-us">About us</Link>
              <Link to="/catalog">Catalog</Link>
              <Link to="/#contact">Contact</Link>
              <Link to ="/faq">FAQ</Link>
            </nav>
            <div className="footer__search" onClick={toggleSearchVisibility}>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.299 18.5305C21.8393 16.605 22.7603 14.1626 22.7603 11.5051C22.7603 5.28909 17.7211 0.25 11.5051 0.25C5.28909 0.25 0.25 5.28909 0.25 11.5051C0.25 17.7211 5.28909 22.7603 11.5051 22.7603C14.1626 22.7603 16.605 21.8393 18.5305 20.299L25.6151 27.3838C26.1035 27.8721 26.8954 27.8721 27.3838 27.3838C27.8721 26.8954 27.8721 26.1035 27.3838 25.6151L20.299 18.5305ZM11.5051 20.2716C6.6635 20.2716 2.73859 16.3468 2.73859 11.5051C2.73859 6.6635 6.6635 2.73859 11.5051 2.73859C16.3468 2.73859 20.2716 6.6635 20.2716 11.5051C20.2716 16.3468 16.3468 20.2716 11.5051 20.2716Z" fill="white" />
              </svg>
            </div>
            <div className={`footer__searchPop ${isSearchVisible ? 'show' : ''}`}>
              <input 
                type="text" 
                placeholder="Search..." 
                value={searchQuery} 
                onChange={handleInputChange}
                onKeyPress={handleKeyPress} 
              />
              <span>
                <Link to={`/search?term=${searchQuery}`}>
                  <img src={searchImg} alt="Search Icon" />
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className='specnamefooter'>All rights reserved © 2024 Resanta</div>
      </footer>
      <button 
        className={`scroll-to-top ${isVisible ? 'show' : ''}`} 
        onClick={scrollToTop}
      >
        ↑
      </button>
    </>
  );
};

export default Footer;
