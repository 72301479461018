import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import CatalogNav from "./CatalogNav";
import ProductCard from "./ProductCard";
import ContentLoader from "react-content-loader";
import Breadcrumbs from "../Breadcrumb"; // Import the Breadcrumbs component
const CatalogPage = () => {
  const location = useLocation(); 
  const [products, setProducts] = useState([]);
  const thisPage = location.state?.currentPage || 1;
  console.log(thisPage);
  
  const [currentPage, setCurrentPage] = useState(thisPage);
  const [totalProd, setTotalProd] = useState(0);
  const [productsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [pageChanging, setPageChanging] = useState(false);
 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  let urlPlus = "";
  if (id) {
    urlPlus = `&category=${id}`;
  }

  console.log(currentPage);
  

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://resanta.org/wordpress/wp-json/wc/v3/products?per_page=${productsPerPage}&page=${currentPage}${urlPlus}`,
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              "ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d"
            ),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        setTotalProd(response.headers.get("X-Wp-Total"));
        return response.json();
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => {
        setLoading(false);
        setPageChanging(false);
      });
  }, [currentPage, urlPlus, productsPerPage]);

  const handlePageChange = (pageNumber) => {
    setPageChanging(true);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPageChanging(true);
      setCurrentPage((prevPage) => prevPage - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPageChanging(true);
      setCurrentPage((prevPage) => prevPage + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const totalPages = Math.ceil(totalProd / productsPerPage);

  const generatePagination = () => {
    const pages = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, 5, '...');
      } else if (currentPage > totalPages - 3) {
        pages.push('...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push('...', currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, '...');
      }
    }

    return pages;
  };

  const SkelArray = [
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      key={1}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>,
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      key={2}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>,
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      key={3}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>,
  ];
  
  
  const ProdArray = products.map((item) => (
    <Link
      to={{pathname: `/catalog/product/${item.id}`}}
      state = {{currentPage, id}}
      key={item.id}
    >
      <ProductCard
        catName={item.categories && item.categories[0] ? item.categories[0].name : 'No Category'}
        Name={item.name}
        image={item.images && item.images[0] ? item.images[0].src : 'placeholder.jpg'}
        descr={item.description ? item.description : 'No description available'}
      />
    </Link>
  ));
  
  

  return (
    <div className="CatalogPage">
      <div className="container">
        <div className="navCategories">
          <CatalogNav />
        </div>
        <div className="producCards">
          <Breadcrumbs />
          {loading || pageChanging ? SkelArray : ProdArray}
        </div>
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Prev
        </button>
        {generatePagination().map((page, index) =>
          page === '...' ? (
            <span key={index} className="pagination-ellipsis">
              
            </span>
          ) : (
            <button
              key={index}
              onClick={() => handlePageChange(page)}
              className={`pagination-button ${page === currentPage ? "active" : ""}`}
            >
              {page}
            </button>
          )
        )}
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CatalogPage;
