import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import BtnTo from '../BtnTo';
import ContentLoader from "react-content-loader";
import Breadcrumbs from "../Breadcrumb"; // Убедитесь, что Breadcrumbs принимает категории
import { motion } from "framer-motion";

const ProductPage = () => {
    const [productData, setProductData] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const { productId } = useParams();
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const location = useLocation();  // Получаем объект location
    const currentPage = location.state?.currentPage || 1; // Получаем currentPage, если оно передано
    const idCat = location.state?.id || null; // Получаем currentPage, если оно передано

    console.log(currentPage);
    

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        // Получение данных о продукте
        fetch(`https://resanta.org/wordpress/wp-json/wc/v3/products/${productId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Basic ' + btoa('ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setProductData(data);
            setCurrentImageIndex(0); // Устанавливаем индекс текущего изображения
            if (data.categories && data.categories.length > 0) {
                return data.categories[0].id;  // Возвращаем id первой категории
            } else {
                // Если категорий нет, возвращаем null или можно обработать это иначе
                return null;
            }
        })
        .then(categoryId => {
            // Получение данных о похожих продуктах
            fetch(`https://resanta.org/wordpress/wp-json/wc/v3/products?category=${categoryId}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Basic ' + btoa('ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d'),
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                const filteredProducts = data.filter(product => product.id !== parseInt(productId));
                const randomProducts = filteredProducts.sort(() => 0.5 - Math.random()).slice(0, 4);
                setSimilarProducts(randomProducts);
            })
            .catch(error => {
                console.error('Error fetching similar products:', error);
            });
        })
        .catch(error => {
            console.error('Error fetching product data:', error);
        })
        .finally(() => setLoading(false));
    }, [productId]);

    const handlePrevImage = () => {
        const newIndex = currentImageIndex === 0 ? productData.images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(newIndex);
    };

    const handleNextImage = () => {
        const newIndex = currentImageIndex === productData.images.length - 1 ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(newIndex);
    };

    const SkelArray = [
        <ContentLoader
          key="loader"
          speed={2}
          width={"100%"}
          height={408}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
        </ContentLoader>
    ];


    const verstka = productData && (
        <>
            <Link  className="btnTo flex row align-center backprodpage" to={{pathname: `/catalog/${idCat || ""}`}} state={{ currentPage }}>  
                <span >Back</span>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="28" cy="28.25" rx="28" ry="27.5" fill="#E55022"/>
                <path d="M45.0607 29.3107C45.6464 28.7249 45.6464 27.7751 45.0607 27.1893L35.5147 17.6434C34.9289 17.0576 33.9792 17.0576 33.3934 17.6434C32.8076 18.2292 32.8076 19.1789 33.3934 19.7647L41.8787 28.25L33.3934 36.7353C32.8076 37.3211 32.8076 38.2708 33.3934 38.8566C33.9792 39.4424 34.9289 39.4424 35.5147 38.8566L45.0607 29.3107ZM12 29.75H44V26.75H12V29.75Z" fill="white"/>
                </svg>
            </Link>
            
            <div className="ProductPage__contentCont">
                {/* Передача категории в хлебные крошки */}
                <Breadcrumbs category={productData.categories[0]} productName={productData.name} />

                
                <div className="ProductPage__contentCHeader">
                    {productData.categories && productData.categories.length > 0 && (
                        <span className="productPage__catName">{productData.categories[0].name}</span>
                    )}
                    <span className="productPage__prodName">{productData.name}</span>
                </div>
                
                <div className="productPage__thumbnailCont">
                    <div className="productPage__imageContainer">
                        {productData.images.map((image, index) => (
                            <img 
                                key={index} 
                                src={image.src} 
                                alt={productData.name} 
                                style={{ display: index === currentImageIndex ? 'block' : 'none' }}
                            />
                        ))}
                        {productData.images.length > 1 && (
                            <>
                                <button className="gallery-nav prev" onClick={handlePrevImage}>&lt;</button>
                                <button className="gallery-nav next" onClick={handleNextImage}>&gt;</button>
                            </>
                        )}
                    </div>
                </div>

                {productData.images.length > 1 && (
                    <div className="productPage__imageGallery">
                        {productData.images.map((image, index) => (
                            <img 
                                key={index} 
                                src={image.src} 
                                alt={productData.name} 
                                onClick={() => setCurrentImageIndex(index)}
                                style={{ cursor: 'pointer', border: index === currentImageIndex ? '1px solid #CE2022' : 'none', borderRadius: '20px' }}
                            />
                        ))}
                    </div>
                )}

                <div className="productPage__infoCont">
                    {productData.tags && productData.tags.includes('new') && (
                        <span className="NewsSticker">New</span>
                    )}
                    
                    <span className="productPage__descrTitle">Description</span>
                    <div className="productPage__description" dangerouslySetInnerHTML={{ __html: productData.description }}></div>
                </div>
            </div>

            <div className="productPage__characteristicsCont">
                {/* Ваш блок характеристик товара */}
            </div>
            
            <div className="productPage__similarProducts">
                <h2>Similar Products</h2>
                <div className="productPage__similarProductsGrid">
                    {similarProducts.map(product => (
                        <motion.div 
                            whileHover={{ y: -20 }}
                            key={product.id}
                        >
                            <Link to={`/catalog/product/${product.id}`} className="productCard">
                                <img src={product.images[0]?.src} alt={product.name} />
                                <span className="productCard__name">{product.name}</span>
                                <span className="productCard__price">{product.price}</span>
                            </Link>
                        </motion.div>
                    ))}
                </div>
            </div>
        </>
    );

    return (
        <div className="productPage">
            <div className="container">
                {loading ? SkelArray : verstka}
            </div>
        </div>
    );
}

export default ProductPage;
