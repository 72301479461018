import React from 'react';

const ComingPage = () => {
    return (
        <section className="comingPage">
            <h1>The website page is under development</h1>
        </section>
    )
}

export default ComingPage;