import { createElement } from "react";
import { useState } from "react";



const ProductCard = ({catName, Name, image, descr}) => {

	const [showChar, setShowChar] = useState(false);

	return(
		<div className="productCard">
			<div className="productCard__leftContent">
				<span className="NewsSticker">
					New
				</span>
				<div className="productCard__image">
					<img src={image} alt="" />
				</div>
			</div>
			<div className="productCard__rightContent">
				<div className="productCard__info">
					<span className="productCard__catName">{catName}</span>
					<span className="productCard__prodName">{Name}</span>
				</div>

				<div className="btnMobOpenChar" onClick={(e) => {e.preventDefault();  setShowChar(!showChar)}}>
					{showChar ? "Collapse" : "Expand"}
				</div>
				<div className={"productCard__characteristics" + (showChar ? " showChar" : "") }>
					<div dangerouslySetInnerHTML={{ __html: descr }} />
					{/* <span>Характеристики</span>
					<table>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
					</table>
					<table>
							<tr>
								<td>смотреть все</td>
							</tr>
					</table> */}


				</div>
			</div>
		</div>
	)
}

export default ProductCard;