import React from 'react';
import { Link } from 'react-router-dom';


const NotFound = () => {
    return (
        <div className='NotFoundPage'>
            <h1>Page not found</h1>
            <p>Sorry, but the page you were trying to view does not exist.</p>
            <Link to="/">Go back to the home page</Link>
        </div>
    )
}


export default NotFound;