import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import ProductCard from "../CatalogPage/ProductCard";
import ContentLoader from "react-content-loader";

const SearchResults = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProd, setTotalProd] = useState(0);
  const [loading, setLoading] = useState(true);
  const productsPerPage = 9;
  const searchQuery = new URLSearchParams(location.search).get("term");

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const urlPlus = searchQuery ? `&search=${searchQuery}` : "";

      try {
        const response = await fetch(
          `https://resanta.org/wordpress/wp-json/wc/v3/products?per_page=${productsPerPage}&page=${currentPage}${urlPlus}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa(
                  "ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d"
                ),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Продукты не найдены");
        }

        const total = response.headers.get("X-Wp-Total");
        setTotalProd(total);

        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Не удалось загрузить продукты:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalProd / productsPerPage);

  const SkelArray = Array(productsPerPage).fill(
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>
  );

  const ProdArray = products.map((item) => (
    <Link to={`/catalog/product/${item.id}`} key={item.id}>
      <ProductCard
        catName={item.categories[0]?.name || "Категория"}
        Name={item.name}
        image={item.images[0]?.src || ""}
        descr={item.description}
      />
    </Link>
  ));

  return (
    <div className="CatalogPage">
      <div className="container">
        <div className="navCategories">
          {/* Навигационные категории (если необходимо) */}
        </div>
        <div className="producCards">{loading ? SkelArray : ProdArray}</div>
      </div>
      <div className="pagination">
        <Swiper
          spaceBetween={10}
          slidesPerView={5}
          centeredSlides={true}
          slideToClickedSlide={true}
          freeMode={false}
          loop={false}
        >
          {[...Array(totalPages)].map((_, index) => (
            <SwiperSlide key={index + 1}>
              <button
                onClick={() => handlePageChange(index + 1)}
                className={`pagination-button ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SearchResults;
