import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ContentLoader from "react-content-loader";

const CatalogNav = () => {
    const [categories, setCategories] = useState([]);
    const { id } = useParams();
    const [backpage, setBackpage] = useState(id);
    const [parentCategoryId, setParentCategoryId] = useState(0);
    const [loading, setLoading] = useState(true);
    
    const fetchData = async () => {
        let url = 'https://resanta.org/wordpress/wp-json/wc/v3/products/categories';
        
        let topLevelCategories = [];
        
        try {
            let totalPages = 1;
            let currentPage = 1;
            
            while (currentPage <= totalPages) {
                let urlPlus = '';
                if (id) {
                   urlPlus = `parent=${id}&`;
                }
                const response = await fetch(`${url}?${urlPlus}per_page=100&page=${currentPage}&hide_empty=true`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Basic ' + btoa('ck_ad1ce0bd580ba47f8d8a07183206757f3cc5fbb0:cs_9b6b6dcebda9d9897fb362dbc746af065b78203d'),
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const categoriesData = await response.json();
                
                topLevelCategories = topLevelCategories.concat(categoriesData);
                // Получаем значение X-Wp-Totalpages из заголовков ответа для определения общего количества страниц
                const totalPagesHeader = response.headers.get('X-Wp-Totalpages');
                if (totalPagesHeader) {
                    totalPages = parseInt(totalPagesHeader);
                }
    
                currentPage++;
            }

            if(id){
                topLevelCategories = topLevelCategories.filter(category => category.parent == id);
             } else{
                topLevelCategories = topLevelCategories.filter(category => category.parent === 0)
             }
            setCategories(topLevelCategories);

            // Если список категорий не пустой, устанавливаем parentCategoryId в id первой категории в списке
            if (topLevelCategories.length > 0) {
                setParentCategoryId(topLevelCategories[0].parent);
            } else {
                // Если список категорий пустой, ищем родительскую категорию, если она есть
                const parentCategoryResponse = await fetch(`${url}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Basic ' + btoa('ck_a9ce86c49402ce867b37ea156612afe08dd76cee:cs_3c8a11614cc23b0b4f7eb656677825dadae986f6'),
                        'Content-Type': 'application/json'
                    }
                });

                if (parentCategoryResponse.ok) {
                    const parentCategoryData = await parentCategoryResponse.json();
                    setParentCategoryId(parentCategoryData.parent);
                }
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchData();
	  
    }, [id]);


    const SkelArray = [
        <ContentLoader
          speed={2}
          width={"179"}
          height={20}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="179" height="20" />
        </ContentLoader>,
        <ContentLoader
          speed={2}
          width={"179"}
          height={20}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="179" height="20" />
        </ContentLoader>,
        <ContentLoader
          speed={2}
          width={"179"}
          height={20}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="179" height="20" />
        </ContentLoader>,
      ];

    let content = null;
    if (categories.length > 0) {
        const CarArray = categories.map(item => (
            <li key={item.id}><a href={`/catalog/${item.id}`}>{item.name}</a></li>
        ));
      
        content = (
            <ul>
                <li><button onClick={() => window.history.back()}>↤ Back</button></li>
                {CarArray}
            </ul>
        );
    } else {
        // Если список категорий пустой, проверяем наличие родительской категории и устанавливаем ссылку "Назад"
        if (parentCategoryId) {
            content = (
                <div>
                    <button onClick={() => window.history.back()}>↤ Back</button>
                </div>
            );
        } else {
            content = (
                <div>
                    <button onClick={() => window.history.back()}>↤ Back</button>
                </div>
            );
        }
    }
    
    return (
        <div className="navCategories__card">
            {loading ? SkelArray : content}
        </div>
    );
}

export default CatalogNav;
