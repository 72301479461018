import { React, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import CaregoriesCard from './CaregoriesCard';
import ContentLoader from "react-content-loader"

const Categories = ({categories, status}) => {
    const swiperRef = useRef(null);
    console.log(categories);
    const handleNextSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePrevSlide = () => {
	if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
	    swiperRef.current.swiper.slidePrev();
	}
  };


  const CarArray = categories.map(item => {
        return (
            <SwiperSlide key={item.id}>
                <CaregoriesCard 
                    title={item.name} 
                    descr={item.description} 
                    link={`/catalog/${item.id}`} 
                />
            </SwiperSlide>
        );
});


    const SkelArray = [
        <SwiperSlide>
            <ContentLoader 
            speed={2}
            width={294}
            height={408}
            viewBox="0 0 294 408"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width="294" height="408" /> 
        </ContentLoader>
        </SwiperSlide>,
        <SwiperSlide>
        <ContentLoader 
        speed={2}
        width={294}
        height={408}
        viewBox="0 0 294 408"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="0" ry="0" width="294" height="408" /> 
    </ContentLoader>
    </SwiperSlide>,
    <SwiperSlide>
    <ContentLoader 
    speed={2}
    width={294}
        height={408}
    viewBox="0 0 294 408"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
>
    <rect x="0" y="0" rx="0" ry="0" width="294" height="408" /> 
</ContentLoader>
</SwiperSlide>
    ]


    return (
        <section className="categories">
            <div className="container flex">
                <div className="categories__leftContent">
                    <div className="categories__card flex">
                        <h2 className="titleSection">
                            Categories
                        </h2>
                    </div>
                    <div className='categories__buttons hidemob'>
                        <button onClick={handlePrevSlide}><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6.5L11.25 0.00480938V12.9952L0 6.5Z" fill="#FFF" />
                    <path d="M0 6.5L11.25 0.00480938V12.9952L0 6.5Z" fill="#FFF" />
                    </svg></button>
                        <button onClick={handleNextSlide}><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6.5L0.75 12.9952V0.00480938L12 6.5Z" fill="#FFF" />
                        <path d="M12 6.5L0.75 12.9952V0.00480938L12 6.5Z" fill="#FFF" />
                    </svg></button>
                            </div>
                </div>


                <div className="categories__rightContent">
                    <div className='categories__rightContent__overlayRight'></div>
                    <div className='categories__rightContent__overlayLeft'></div>
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={33}
                        slidesPerView={'auto'}
                        freeMode={true}
                        loop={true}
                    >

                    {status ? (
                            SkelArray
                        ) : (
                            CarArray
                        )}
                    </Swiper>
                </div>

                
                <div className="categories__leftContent hidepc">
                    
                    <div className='categories__buttons'>
                        <button onClick={handlePrevSlide}><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6.5L11.25 0.00480938V12.9952L0 6.5Z" fill="#FFF" />
                    <path d="M0 6.5L11.25 0.00480938V12.9952L0 6.5Z" fill="#FFF" />
                    </svg></button>
                        <button onClick={handleNextSlide}><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6.5L0.75 12.9952V0.00480938L12 6.5Z" fill="#FFF" />
                        <path d="M12 6.5L0.75 12.9952V0.00480938L12 6.5Z" fill="#FFF" />
                    </svg></button>
                            </div>
                </div>




            </div>
        </section>
    );
};

export default Categories;
